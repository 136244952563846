.hero {
	height: 100vh;
	position: relative;
	background: $black;
	min-height: 320px;

	.btn-header {
		color: $gold;
		font-size: $small-size;
		border: solid 1px $gold;
	    padding: 0 20px;
	    line-height: 28px;
		position: absolute;
		top: 40px;
		z-index: 21;
	    transition: all .1s ease;

	    &.contact-us {
			right: 40px;
		}

		&.call-us {
			left: 40px;
			width: auto;
			display: block;

			@media screen and (min-width:768px) {
				display: none;
			}
		}
	    @media screen and (min-width: 768px) {
			position: fixed;
		}

		&:hover {
			background-color: $gold;
			color: $black;
		}
	}
	
	.hero-logo {
		width: 100px;
	    display: inline-block;
	    position: absolute;
	    left: 0;
	    right: 0;
	    top: 40%;
	    margin: auto;

	    @media screen and (max-width: 767px) and (orientation:landscape) {
	    	top: 25%;
	    }
	    @media screen and (min-width: 768px) {
			width: 160px;
		}
	}

	.arrow-animate {
		position: absolute;
	    left: 0;
	    right: 0;
	    bottom: 7%;
	    margin: auto;
	    text-align: center;
	    z-index: 10;
	    width: 51px;
	    
	    @media screen and (min-width: 768px) {
			bottom: 125px;
		}
	}
}