.live-module {

	.row {
		@media screen and (max-width: 776px) {
			flex-direction: column;
		}
	}

	.grid-less-half {

		article {
			flex: 1 1 60%;
			order: 2;
			@media screen and (max-width: 776px){
				flex: 1 1 auto;
			}

			section {
				.content {
					h3 {
						padding-bottom: 24px;
					}
				}
			}
		}

		figure.desktop-figure, figure.mobile-figure {
			flex: 1 1 40%;
			order: 1;

			@media screen and (max-width: 776px){
				flex: 1 1 auto;
				max-height: 400px;
				overflow: hidden;
			}
		}

		figure.desktop-figure {
			height: 700px;
			display: flex;
			justify-content: center;

			img {
				width: 100px;
				height: 100px;
				margin-top: 120px;

				@media screen and (min-width: 776px) and (max-width: 1440px) {
					margin-left: -120px;
				}
			}
		} 
		
		.arrow-animate {
			right: 10%;
			bottom: 4%;
			z-index: 10;
			@media screen and (min-width: 776px){
				right: 5%;
				bottom: 10%;
			}
		}
	}

	.grid-half {

		article {
			min-height: 900px;
			position: relative;

			@media screen and (max-width: 776px){
				flex: 1 1 auto;
			}


			section {
				.content {
					h3 {
						padding-bottom: 0;
					}

					p {
						margin-bottom: 35px;

						@media screen and (min-width: 777px) and (max-width: 960px) {
							margin-bottom: 5px;
						}

						&:last-child {
							margin: 0;
						}
					}
				}
			}
		}

		.arrow-animate {
			right: 10%;
			bottom: 4%;

			@media screen and (min-width: 768px){
				bottom: 10%;
			}
		}

		figure.desktop-figure, figure.mobile-figure {
			flex: 1 1 50%;

			@media screen and (max-width: 776px){
				flex: 1 1 auto;
				max-height: 400px;
			}
		}

		@media screen and (max-width: 776px){

			article {
				order:2;
			}
			figure {
				order:1;
			}
		}
	}

	.grid-less-half, .grid-half {

		article {
			padding: 35px 35px 120px; 
			transition : all 3s ease;
			
			@include breakpoint (phablet) {
				padding:  65px;
			}

			@include breakpoint (desktop) {
				padding:  100px 90px;
			}

		    h1 {
		    	margin-bottom: 55px;
		    	font-weight: $font-black;
		    	font-size: 30px;
		    	line-height: 30pt;
		    	color: $gray;
		    	position: relative;
		    	text-transform: uppercase;
		    	display: inline-block;
		    	
		    	&:after {
		    		content: '';
		    		position: absolute;
		    		bottom: -26px;
		    		left: 0;	
		    		width: 10px;
		    		height: 2px;
		    		background: $black;		    		
		    	}

		    }
		    section {

		    	h2 {
		    		font-weight: $font-bold;
		    		font-size: $title-middle;
				    letter-spacing: 2px;
				    text-transform: uppercase;
				    margin-bottom: 55px;				    
		    	}
		    	.content {

		    		h3 {
		    			font-weight: bold;
		    			text-transform: uppercase;
		    		}

		    		p {
		    			color: $gray;
		    		}
		    	}
		    }
		}

		figure.desktop-figure {
		    background-size: cover;

		    @media screen and (max-width: 776px) {
		    	display: none;
		    }
		}

		figure.mobile-figure {
		   	display: none;

		    @media screen and (max-width: 776px) {
		    	display: block;
		    	overflow: hidden;
		    }
		}
	}
}

.live-module {
	
	&.map-module {
		position: relative;
		min-height: 800px;

		@media screen and (min-width: 1024px) {
			height: 700px; 
			background-color: #5c5c5c;
			overflow: hidden;
		}
		.row {
			&.img {
				position: relative;
				@media screen and (min-width: 776px) { 
					height: 100%;
					width: auto;
				}

				&.row-map { 
					display: none;

					@media screen and (min-width: 768px) {
						display: block;
					}
				}

				figure {
					overflow: hidden;

					img {
						height: 100%;
		    			width: auto;

		    			@media screen and (max-width: 776px) {
		    				width: 100%;
		    				height: 100%;
		    				position: absolute;
		    				right: 0;
		    				bottom: 0;
		    			}
					}	
				}

				@media screen and (max-width: 776px) {
					order:2;
					height: auto;
				}
				.map-text {
					color: red;
					position: absolute;
					left: 29%;
					top: 0;
					bottom: 0;
					z-index: 15;
				}
				#map-wrapper {
					width: 100%;
    				height: 100%;
					z-index: 1;
					transition: all 1s ease;
					right: -50%;

					&.opened-map {
						right: 0%;	
					}
				}
			}
		}
		
		.row {
			&.row-map {
			
				&.slide {
					width: 80%;
					position: absolute;
					z-index: 3;
					top: 0;
					transition: all 1s ease;
					height: 100%;
					left: 0;
					
					article {
						transition: all 1s ease;
						h1, section {
							opacity: 1;
							transition: all .3s ease;
						}
					}
						

					@media screen and (max-width: 776px) {
						width: 100%;
						position: relative;
						height: auto;
						order : 2;
					}
					
					.donwload-map-section {
						padding: 0;
						width: 100%;
						height: 20vh;
						display: flex;
					    justify-content: center;
					    align-items: center;
					    background-color: #2B2B2B;
					    
						footer {

							.download-map {
								color: $gold;
								font-size: $small-size;
								border: solid 1px $gold;
							    padding: 0 20px;
							    line-height: 28px;
							    padding: 10px 20px;
							}
						}

						@media screen and (min-width: 769px) {
							display: none;
						}
					}
				}
				&.over-map {
					width: 20%;
					background-color: transparent;
					position: absolute;
					z-index: 2;
					height: 100%;
					right: 0;
					top: 0;
					transition: all 1s ease;
					display: none;

					@media screen and (min-width: 776px) {
						display: block;
					}

    				.left-text, .top-text, .right-text, .bottom-text {
    					opacity: 0;
						position: absolute;
						text-transform: uppercase;
						color: $light-gray;					
    				}

					.left-text {
						transition: all 1s ease;
						top: 50%;
					    transform: rotate(90deg);
					    
					    &:before {
					    	content: " ";
					    	width: 16px;
					    	height: 16px;
					    	display: block;
					    	background: url(../images/arrow-n.svg);
					    	position: absolute;
    						transform: translateX(-50%) rotate(90deg);
						    left: 50%;
						    bottom: -100%;
					    }
					}

					.top-text {
						top: 5%;
						left: 50%;
    					transform: translateX(-50%);
    					
						&:before {
					    	content: " ";
						    width: 14px;
						    height: 14px;
						    display: block;
						    background: url(../images/arrow-n.svg);
						    position: absolute;
						    left: 50%;
						    transform: translateX(-50%) rotate(-90deg);
						    bottom: 100%;
					    }
					}

					.right-text {
						transition: al 1s ease;
						top: 50%;
					    transform: rotate(-90deg);
					    right: 3%;
					    &:before {
					    	content: " ";
						    width: 16px;
						    height: 16px;
						    display: block;
						    background: url(../images/arrow-n.svg);
						    position: absolute;
						    transform: translateX(-50%) rotate(90deg);
						    left: 50%;
						    top: 100%;
					    }
					}

					.bottom-text {
						bottom: 5%;
						left: 50%;
						transform: translateX(-50%);
						&:before {
					    	content: " ";
						    width: 14px;
						    height: 14px;
						    display: block;
						    background: url(../images/arrow-n.svg);
						    position: absolute;
						    left: 50%;
						    transform: translateX(-50%) rotate(90deg);
						    top: 100%;
					    }
					}
				}
			}
		}

		.row {
			&.row-map {
				&.slide {
					&.hide {
						width: 12%;
						transition: all .6s ease;
						article {
							padding: 0;
							h1, section {
								opacity: 0;	
							}
						}
						.arrow-animate {
							right: 3%;
							@media screen and (min-width: 1025px) {
								right: 16%;	
							}
							span {
								margin-left: -29px;		
							}
						}
						.hide-content {
							opacity: 0;
						}

					}
				}

				&.over-map {
					&.hide {
						width: 88%;
						transition: all .6s ease; 
						pointer-events: none;
						
						.left-text, .top-text, .right-text, .bottom-text  {
						    opacity: 1;
						}
					}
				}
			}
		}
		
		.grid-less-half {
			article {
				background-color: $mapGrayBg;
				padding-bottom: 35px;

				h1 {
					color: $white;
					
					&:after {
						background: $white;
					}
				}
				section {

					h2 {
						color: $white;
					}	

					.content {
						p {
							width: 60%;
							margin-bottom: 35px;
							color: $white;
							font-size: 16px;
							@media screen and (max-width: 776px) {
								width: 100%;
							}

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}

			.arrow-animate {
				right: 5%;
				bottom: 45%;

				.wrapperArrow {
					.circle {
						svg {
							path {
								fill:$light-gray;
							}
						}
						.arrow {
							transform: rotate(-90deg);
							transition: transform .5s ease;
						}
						.right-arrow {
							transform: rotate(90deg);
							transition: transform .5s ease;
						}
					}
					&:hover {
						.circle {
							.arrow {
								transform: rotate(90deg);
							}
							.right-arrow {
								transform: rotate(-90deg);
							}
						}	
					}
				}

				span {
					color: $white;
					display: block;
					text-transform: uppercase;
					margin-left: -10px;
					font-family: $font-base;
					font-size: $smaller-size;
					letter-spacing: 2pt;
					transition: all 1s ease;
					// opacity: 1;
					transform: rotate(0deg);
				}

				span {
					&.hide {
						// opacity: 0;
						transform:rotate(360deg);
						margin-left: -30px;
					}
				}
				@media screen and(max-width: 776px) {
					display: none;
				}	
			}

			.arrow-animate.hide {
				right: 3%;
			}
		}

		@media screen and (max-width: 776px) {
			display: flex;
			flex-wrap: wrap;
		}
	}
}