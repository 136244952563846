.contact-slide {
	position: fixed;
	width: 100%;
	transition: all .7s ease;	
	right: -150%;
	background-color: black;
	z-index: 100;
	height: 100%;
    overflow-y: scroll;
    display: flex;

    @media screen and (min-width: 768px) {
    	overflow-y: visible; 
    }

	.close-icon {
		position: absolute; 
	    right: 5%;
	    top: 20px;
	    width: 25px;
    	height: 25px;
	    cursor: pointer;

	    path {
	    	fill: $gold;
	    }

	    @media screen and (min-width: 768px) {
	    	width: 35px;
    		height: 35px;
    		top: 40px;
	    }
	}

	.contact-content {
		margin: auto;
		flex: 1;
	}

	form {
		width: 100%;
		max-width: 620px;
		padding: 0 20px;
		margin: 0 auto;
	}

	h1 {
		color: $light-gray;
	    text-align: center;
	    font-size: 34px;
	    font-weight: $font-black;
	    padding-bottom: 25px;	   
	    @media screen and (min-width: 768) {
	    	font-size: 34px;
	    }
	}

	.form-group {
		position: relative;
		margin-bottom: 30px;

		&.focus,
		&.active {
			label {
				top: 0;
				font-size: 11px;
			}
		}

		@media screen and (min-width: 768px) {
			margin-bottom: 40px;
		}
	}

	label {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		color: $light-gray;
		font-family: $font-base;
		font-size: 14px;
		line-height: 30px;
		text-transform: uppercase;
		transition: all .2s ease;
	}

	input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
		background-color: red !important;
	}

	label#email-error, label#name-error, label#tel-error, label#comment-error {
		position: absolute;
		top: 70px;
		color: red;
	}

	label#comment-error {
		top: 130px;
	}

	label[for=comment] {
		position: relative;
	}

	input {
		display: block;
		width: 100%;
		border: none;
		background-color: black;
		border-bottom: 1px solid #464646;
		color: $light-gray !important;
		font-family: $font-base;
		font-size: $base-size;
		line-height: 50px;
		text-transform: uppercase;
		
		&.error {
			border-bottom-color: red;
		}
	}
	input:-webkit-autofill {
		background-color: #fff !important;
    	-webkit-box-shadow: inset 0 0 0px 9999px #000;
    	color: white !important;
	}

	textarea {
		width: 100%;
		min-height: 80px;
	    background-color: transparent;
		border: 1px solid #464646;
		resize: none;
		color: $light-gray;
		font-size: $base-size;
		font-family: $font-base;
		text-transform: uppercase;
		
		&.error {
			border-bottom-color: red;
		}
	}

	.actions {
		margin: 0px;
		text-align: center;

		@media screen and (min-width: 768px) {
			margin-top: 60px;
		}
	}

	@media screen and (min-width: 768px) {
		padding: 125px 0 120px;

		h1 {
			padding-bottom: 50px;
		}
	}

	.thanks-content {
		display: flex;
	    justify-content: center;
	    flex-direction: column;

	    p {
		    color: white;
		    width: 40%;
		    margin: 0 auto;
		    text-align: center;
		    font-size: 24px;
		    font-family: $font-base;
		    line-height: 33px;
	    }

	    .form-group {
	    	.btn-dark {
	    		width: auto;
	    		padding-left: 20px;
	    		padding-right: 20px;
	    	}
	    }
	    .success-icon {
			align-self: center;
			margin-bottom: 30px;
	    }
	}
}

.contact-slide.show-form {
	right: 0;
	
	&.thankyou {
		display: flex!important;
	}
}


.contact-slide.sent {
	display: none;
}

.btn-dark {
	padding: 10px;
	border-radius: 0;
	width: 30%;
	background-color: transparent;
	border:1px solid $gold;
	color: $gold;
	font-size: $small-size;
	font-family: $font-base;
	letter-spacing: 2px;
	cursor: pointer;

	&:hover {
		background-color: $gold;
		color: $black;
	}
}