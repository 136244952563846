nav.progressive-nav {
	display: none;

	@media screen and (min-width: 768px) {
		background: $black;
		padding: 25px;
		height: 70px;
		left: 0;
		z-index: 20;
		width: 100%;
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 0px;
	}

	&.fixed {
		position: fixed;
		top: 0px; 
		bottom: inherit;
	}
	
	.wrapper-nav {
		padding: 0;
      	width: 446px;
		height: 40px;
	}

	.logo-nav {
		margin-right: 15px;
		height: 21px;
		width: 18px;
		float: left;
		cursor: pointer;
		fill: $white;

		&:hover * {
			transition: all .5s ease;
			fill: $gold;
		}
	}

	.nodes {
		position: relative;
		margin-top: -5px;
		margin-left: 5px;
		float: left;
	}

	.line {
		position: absolute;
		width: 400px;
		height: 1px;
		top: 14px;
	  
		.red, .blue {
			width: 100%;
			height: 1px;
		}
	  
	   	.red-container {
			overflow: hidden;
			height: 1px;
			width: 58px;
	   	}
	  
		.red {
			background-color: $gold;
	  	}
	  
	  	.blue {
			background-color: $white;
	  	}
	}

	.node {
		margin-left: 90px;
		width: 20px;
		height: 20px;
		margin-top: 5px;
		float: left;
		margin-right: -10px;
		position: relative;

		.splash {
			box-shadow: 0px 0px 8px 2px rgba(220, 165, 110, 0.53);
			transform: translateZ(0px) scale( 1 );
			position: absolute;
			width: 20px;
			height: 20px;
			border-radius: 100%;
			pointer-events: none;
			opacity: 1;
		}

		&.active {

			.active-circle {
				transform: scale(1);
				opacity: 1;
			}

			.splash {
				transition: transform 1200ms, opacity 1500ms;
				transform: translateZ(0px) scale( 2.5 );
				opacity: 0 !important;
			}

			.inactive-circle {
				transition: opacity 200ms;
				opacity: 0; 
			}
		}

		.active-circle {
			transition: all 300ms cubic-bezier(.63,.62,.48,1.84);
			transform: scale(0.7);
			position: absolute;
			opacity: 0;
			width: 20px;
			height: 20px;
			border-radius: 100%;

			background: rgb(220,165,110); /* Old browsers */ 
			background: linear-gradient(45deg,  rgba(220,165,110,1) 0%,rgba(108,65,36,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */			

			.cover {
				transform: translateZ( 0px );
				width: 16px;
				height: 16px;
				background: black;
				border-radius: 100%;
				position: absolute;
				top: 2px;
				left: 2px;
			}

			.white {
				transition: all 300ms cubic-bezier(.63,.62,.48,1.84);
				width: 6px;
				height: 6px;
				background: $gold;
				border-radius: 100%;
				position: absolute;
				transform: translateX(5px) translateY(5px) scale(1);
			}
		}

		.inactive-circle {
			transition: all 300ms cubic-bezier(.63,.62,.48,1.84);
			width: 14px;
			height: 14px;
			display: block;
			background: #000;
			position: absolute;
			border-radius: 100%;
			border: 2px solid $white;
			top: 3px;
			left: 3px;
		}

		&:hover {
	      
	    	$quickTransition: all 200ms cubic-bezier(.63,.62,.48,1.84);
			cursor: pointer;

			.active-circle {
				transition: $quickTransition;
				transform: scale(0.84);

				.white {
					transition: $quickTransition;
					transform: translateX(4.7px) translateY(5px) scale(1.42);	
				}
			}

			.inactive-circle {
				transition: $quickTransition;
				transform: scale( 0.85 );
			}
		}
	}
}