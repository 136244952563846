/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	//font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5; 
	font: 400 14px/24px $font-base;
	-webkit-font-smoothing: antialiased; 
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
.clearfix:after { 
   content: "."; 
   visibility: hidden; 
   display: block; 
   height: 0; 
   clear: both;
}
a { 
	text-decoration: none;
}

/* CONTENT */
*, *:before, *:after {
	box-sizing: inherit;
}

html,
body {
	font-family: $font-base;
	position: relative;
	-webkit-font-smoothing: antialiased;
	background: $white;
}

html {
	box-sizing: border-box;
	background-color: $black; 
}

main {
	flex: 2 1 auto;
}

img {
	width: 100%;
	height: auto;
	display: block;
}

.wrapper {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	position: relative;
}

.link--default {
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: .5px;
}

*:focus { outline: 0; }


// ANIMATED ARROW

.arrow-animate {
	position: absolute;
}

.wrapperArrow {
	position: relative;
	width:100%;
	height:100%;
}
.circle {
	width: 51px;
	height: 51px;
	border-radius: 100%;
	border: 2px dotted transparent;
}
.inner {
	width: 51px;
	height: 51px;
	border-radius: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	animation: borderSpin 9s linear infinite;
	border: 2px dotted $brown;
}
.arrow, .right-arrow {
	display: block;
    width: 21px;
    height: 100%;
    margin: auto;

	path {
		fill: $gold;
	}
}
.wrapperArrow:hover .circle {
	// background: rgba(0,0,0,1.00);
	// color: red;
}
.wrapperArrow:hover .inner {
	animation-play-state: paused;
}

@keyframes borderSpin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}