// FONTS
$font-base:				'Lato', sans-serif; /* 400 */
$font-bold:				700;
$font-black:			900;

// COLORS
$gold:				#ebb276;
$gray:				#000000;
$light-gray:		#858585;
$brown:				#704223;
$black:				#000;
$white:				#fff;
$mapGrayBg:			#393939;

// FONTS SIZES
$base-size:			14px;
$small-size:		13px;
$title-middle:		21px;
$big-size:			30px;
$smaller-size:		11px;

// BREAKPOINTS
@mixin breakpoint($point) {
	@if $point == desktop {
		@media (min-width: 1440px) { @content ; }
	}
	@else if $point == laptop {
		@media (min-width: 1200px) { @content ; }
	}
	@else if $point == tablet {
		@media (min-width: 1024px) { @content ; }
	}
	@else if $point == phablet {
		@media (min-width: 768px)  { @content ; }
	}
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	//font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5; 
	font: 400 14px/24px $font-base;
	-webkit-font-smoothing: antialiased; 
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
.clearfix:after { 
   content: "."; 
   visibility: hidden; 
   display: block; 
   height: 0; 
   clear: both;
}
a { 
	text-decoration: none;
}

/* CONTENT */
*, *:before, *:after {
	box-sizing: inherit;
}

html,
body {
	font-family: $font-base;
	position: relative;
	-webkit-font-smoothing: antialiased;
	background: $white;
}

html {
	box-sizing: border-box;
	background-color: $black; 
}

main {
	-ms-flex: 2 1 auto;
	    flex: 2 1 auto;
}

img {
	width: 100%;
	height: auto;
	display: block;
}

.wrapper {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	position: relative;
}

.link--default {
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: .5px;
}

*:focus { outline: 0; }


// ANIMATED ARROW

.arrow-animate {
	position: absolute;
}

.wrapperArrow {
	position: relative;
	width:100%;
	height:100%;
}
.circle {
	width: 51px;
	height: 51px;
	border-radius: 100%;
	border: 2px dotted transparent;
}
.inner {
	width: 51px;
	height: 51px;
	border-radius: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	animation: borderSpin 9s linear infinite;
	border: 2px dotted $brown;
}
.arrow, .right-arrow {
	display: block;
    width: 21px;
    height: 100%;
    margin: auto;

	path {
		fill: $gold;
	}
}
.wrapperArrow:hover .circle {
	// background: rgba(0,0,0,1.00);
	// color: red;
}
.wrapperArrow:hover .inner {
	animation-play-state: paused;
}

@keyframes borderSpin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.footer-section {
	width: 100%;
	background-color: $black;
	padding: 16px;

	.footer-content {
		min-height: 140px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: justify;
		    justify-content: space-between;
		-ms-flex-align: center;
		    align-items: center;
		padding: 10px 0;
		-ms-flex-direction: column;
		    flex-direction: column;
		text-align: center;

		@media screen and (min-width: 768px) {
			-ms-flex-direction: row;
			    flex-direction: row;
			padding: 0 70px;
			text-align: left;
		}			

		.info {
			
			display: -ms-flexbox;
			
			display: flex;
			-ms-flex-align: center;
			    align-items: center;
			-ms-flex-direction: column;
			    flex-direction: column;

			@media screen and (min-width: 768px) {
				-ms-flex-direction: row;
				    flex-direction: row;
				-ms-flex: 2 1 33%;
				    flex: 2 1 33%;
			}

			h1 {
			    width: 100px;
				
				@media screen and (min-width: 768px) {
					margin-right: 30px;
					width: 75px;
				}
			}

			h2 {
				color: $light-gray;
				font-weight: normal;
				
				a {
					display: block;
					color: $light-gray;
				}
			}
		} 
		.to-top.arrow-animate {
			-ms-flex: 1 1 auto;
			    flex: 1 1 auto;
			position: static;
			margin: 20px 0;

			@media screen and (min-width: 768px){	
				margin: 0;
			}

			.wrapperArrow {
				width: 51px;
				margin: auto;

				.arrow {
					transform: rotate(180deg);
				}
			}
		}
		.footer-nav {
			color: $gold;
			font-weight: normal;

			.footer-facebook {
				margin-right: 20px;
			}
			
			@media screen and (min-width: 768px){	
				text-align: right;
				-ms-flex: 2 1 33%;
				    flex: 2 1 33%; 
			}
			
			a {
				color: $gold;
			}
			span {
				display: block;

				@media screen and (min-width: 768px){
					display: inline-block;
				}

				img {
					width: 93px;
					display: inline-block;
					vertical-align: middle;
					
					@media screen and (min-width: 768px){
						margin-right: 30px;
					}
				}
			}
		}
	}
}

.contact-slide {
	position: fixed;
	width: 100%;
	transition: all .7s ease;	
	right: -150%;
	background-color: black;
	z-index: 100;
	height: 100%;
    overflow-y: scroll;
    display: -ms-flexbox;
    display: flex;

    @media screen and (min-width: 768px) {
    	overflow-y: visible; 
    }

	.close-icon {
		position: absolute; 
	    right: 5%;
	    top: 20px;
	    width: 25px;
    	height: 25px;
	    cursor: pointer;

	    path {
	    	fill: $gold;
	    }

	    @media screen and (min-width: 768px) {
	    	width: 35px;
    		height: 35px;
    		top: 40px;
	    }
	}

	.contact-content {
		margin: auto;
		-ms-flex: 1;
		    flex: 1;
	}

	form {
		width: 100%;
		max-width: 620px;
		padding: 0 20px;
		margin: 0 auto;
	}

	h1 {
		color: $light-gray;
	    text-align: center;
	    font-size: 34px;
	    font-weight: $font-black;
	    padding-bottom: 25px;	   
	    @media screen and (min-width: 768) {
	    	font-size: 34px;
	    }
	}

	.form-group {
		position: relative;
		margin-bottom: 30px;

		&.focus,
		&.active {
			label {
				top: 0;
				font-size: 11px;
			}
		}

		@media screen and (min-width: 768px) {
			margin-bottom: 40px;
		}
	}

	label {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		color: $light-gray;
		font-family: $font-base;
		font-size: 14px;
		line-height: 30px;
		text-transform: uppercase;
		transition: all .2s ease;
	}

	input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
		background-color: red !important;
	}

	label#email-error, label#name-error, label#tel-error, label#comment-error {
		position: absolute;
		top: 70px;
		color: red;
	}

	label#comment-error {
		top: 130px;
	}

	label[for=comment] {
		position: relative;
	}

	input {
		display: block;
		width: 100%;
		border: none;
		background-color: black;
		border-bottom: 1px solid #464646;
		color: $light-gray !important;
		font-family: $font-base;
		font-size: $base-size;
		line-height: 50px;
		text-transform: uppercase;
		
		&.error {
			border-bottom-color: red;
		}
	}
	input:-webkit-autofill {
		background-color: #fff !important;
    	-webkit-box-shadow: inset 0 0 0px 9999px #000;
    	color: white !important;
	}

	textarea {
		width: 100%;
		min-height: 80px;
	    background-color: transparent;
		border: 1px solid #464646;
		resize: none;
		color: $light-gray;
		font-size: $base-size;
		font-family: $font-base;
		text-transform: uppercase;
		
		&.error {
			border-bottom-color: red;
		}
	}

	.actions {
		margin: 0px;
		text-align: center;

		@media screen and (min-width: 768px) {
			margin-top: 60px;
		}
	}

	@media screen and (min-width: 768px) {
		padding: 125px 0 120px;

		h1 {
			padding-bottom: 50px;
		}
	}

	.thanks-content {
		display: -ms-flexbox;
		display: flex;
	    -ms-flex-pack: center;
	        justify-content: center;
	    -ms-flex-direction: column;
	        flex-direction: column;

	    p {
		    color: white;
		    width: 40%;
		    margin: 0 auto;
		    text-align: center;
		    font-size: 24px;
		    font-family: $font-base;
		    line-height: 33px;
	    }

	    .form-group {
	    	.btn-dark {
	    		width: auto;
	    		padding-left: 20px;
	    		padding-right: 20px;
	    	}
	    }
	    .success-icon {
			-ms-flex-item-align: center;
			    -ms-grid-row-align: center;
			    align-self: center;
			margin-bottom: 30px;
	    }
	}
}

.contact-slide.show-form {
	right: 0;
	
	&.thankyou {
		display: -ms-flexbox!important;
		display: flex!important;
	}
}


.contact-slide.sent {
	display: none;
}

.btn-dark {
	padding: 10px;
	border-radius: 0;
	width: 30%;
	background-color: transparent;
	border:1px solid $gold;
	color: $gold;
	font-size: $small-size;
	font-family: $font-base;
	letter-spacing: 2px;
	cursor: pointer;

	&:hover {
		background-color: $gold;
		color: $black;
	}
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-flow: row nowrap;
	    flex-flow: row nowrap;
	width: 100%;
	position: relative;

	.concept {
		display: -ms-flexbox;
		display: flex;
		min-height: 200px;

		&.dark {
			background-color: $black;
			color: $white;
		}
	}

	h1 {
		margin: auto;
		font-weight: $font-black;
		font-size: $title-middle;
		color: $white;
		text-transform: uppercase;
		position: relative;
	}
}

.grid-half {
	-ms-flex-direction: column;
	    flex-direction: column;

	@include breakpoint (phablet) {
		-ms-flex-direction: row;
		    flex-direction: row;
	}

	figure {
		display: none;

		@include breakpoint (phablet) {
			display: block;	
			overflow: hidden;

			img {
				height: 100%;
				width: auto;
			}
		}

	}

	article, figure {

		@include breakpoint (phablet) {
			-ms-flex: 1 1 50%;
			    flex: 1 1 50%;			
		}
		@include breakpoint (tablet) {
			-ms-flex: 1 1 50%;
			    flex: 1 1 50%;			
		}
	}
}

.grid-quarter {
	max-height: 400px;
	overflow: hidden;

	article, figure {
		-ms-flex: 1 1 25%;
		    flex: 1 1 25%;
		overflow: hidden;
	}

	figure {
		img {	
			width: auto;
			height: 100%;
		}
	}

	.concept {
		display: -ms-flexbox!important;
		display: flex!important;
		height: 400px;

		@include breakpoint (phablet) {
			height: auto;
		}
	}
}
.live-module {

	.row {
		@media screen and (max-width: 776px) {
			-ms-flex-direction: column;
			    flex-direction: column;
		}
	}

	.grid-less-half {

		article {
			-ms-flex: 1 1 60%;
			    flex: 1 1 60%;
			-ms-flex-order: 2;
			    order: 2;
			@media screen and (max-width: 776px){
				-ms-flex: 1 1 auto;
				    flex: 1 1 auto;
			}

			section {
				.content {
					h3 {
						padding-bottom: 24px;
					}
				}
			}
		}

		figure.desktop-figure, figure.mobile-figure {
			-ms-flex: 1 1 40%;
			    flex: 1 1 40%;
			-ms-flex-order: 1;
			    order: 1;

			@media screen and (max-width: 776px){
				-ms-flex: 1 1 auto;
				    flex: 1 1 auto;
				max-height: 400px;
				overflow: hidden;
			}
		}

		figure.desktop-figure {
			height: 700px;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-pack: center;
			    justify-content: center;

			img {
				width: 100px;
				height: 100px;
				margin-top: 120px;

				@media screen and (min-width: 776px) and (max-width: 1440px) {
					margin-left: -120px;
				}
			}
		} 
		
		.arrow-animate {
			right: 10%;
			bottom: 4%;
			z-index: 10;
			@media screen and (min-width: 776px){
				right: 5%;
				bottom: 10%;
			}
		}
	}

	.grid-half {

		article {
			min-height: 900px;
			position: relative;

			@media screen and (max-width: 776px){
				-ms-flex: 1 1 auto;
				    flex: 1 1 auto;
			}


			section {
				.content {
					h3 {
						padding-bottom: 0;
					}

					p {
						margin-bottom: 35px;

						@media screen and (min-width: 777px) and (max-width: 960px) {
							margin-bottom: 5px;
						}

						&:last-child {
							margin: 0;
						}
					}
				}
			}
		}

		.arrow-animate {
			right: 10%;
			bottom: 4%;

			@media screen and (min-width: 768px){
				bottom: 10%;
			}
		}

		figure.desktop-figure, figure.mobile-figure {
			-ms-flex: 1 1 50%;
			    flex: 1 1 50%;

			@media screen and (max-width: 776px){
				-ms-flex: 1 1 auto;
				    flex: 1 1 auto;
				max-height: 400px;
			}
		}

		@media screen and (max-width: 776px){

			article {
				-ms-flex-order:2;
				    order:2;
			}
			figure {
				-ms-flex-order:1;
				    order:1;
			}
		}
	}

	.grid-less-half, .grid-half {

		article {
			padding: 35px 35px 120px; 
			transition : all 3s ease;
			
			@include breakpoint (phablet) {
				padding:  65px;
			}

			@include breakpoint (desktop) {
				padding:  100px 90px;
			}

		    h1 {
		    	margin-bottom: 55px;
		    	font-weight: $font-black;
		    	font-size: 30px;
		    	line-height: 30pt;
		    	color: $gray;
		    	position: relative;
		    	text-transform: uppercase;
		    	display: inline-block;
		    	
		    	&:after {
		    		content: '';
		    		position: absolute;
		    		bottom: -26px;
		    		left: 0;	
		    		width: 10px;
		    		height: 2px;
		    		background: $black;		    		
		    	}

		    }
		    section {

		    	h2 {
		    		font-weight: $font-bold;
		    		font-size: $title-middle;
				    letter-spacing: 2px;
				    text-transform: uppercase;
				    margin-bottom: 55px;				    
		    	}
		    	.content {

		    		h3 {
		    			font-weight: bold;
		    			text-transform: uppercase;
		    		}

		    		p {
		    			color: $gray;
		    		}
		    	}
		    }
		}

		figure.desktop-figure {
		    background-size: cover;

		    @media screen and (max-width: 776px) {
		    	display: none;
		    }
		}

		figure.mobile-figure {
		   	display: none;

		    @media screen and (max-width: 776px) {
		    	display: block;
		    	overflow: hidden;
		    }
		}
	}
}

.live-module {
	
	&.map-module {
		position: relative;
		min-height: 800px;

		@media screen and (min-width: 1024px) {
			height: 700px; 
			background-color: #5c5c5c;
			overflow: hidden;
		}
		.row {
			&.img {
				position: relative;
				@media screen and (min-width: 776px) { 
					height: 100%;
					width: auto;
				}

				&.row-map { 
					display: none;

					@media screen and (min-width: 768px) {
						display: block;
					}
				}

				figure {
					overflow: hidden;

					img {
						height: 100%;
		    			width: auto;

		    			@media screen and (max-width: 776px) {
		    				width: 100%;
		    				height: 100%;
		    				position: absolute;
		    				right: 0;
		    				bottom: 0;
		    			}
					}	
				}

				@media screen and (max-width: 776px) {
					-ms-flex-order:2;
					    order:2;
					height: auto;
				}
				.map-text {
					color: red;
					position: absolute;
					left: 29%;
					top: 0;
					bottom: 0;
					z-index: 15;
				}
				#map-wrapper {
					width: 100%;
    				height: 100%;
					z-index: 1;
					transition: all 1s ease;
					right: -50%;

					&.opened-map {
						right: 0%;	
					}
				}
			}
		}
		
		.row {
			&.row-map {
			
				&.slide {
					width: 80%;
					position: absolute;
					z-index: 3;
					top: 0;
					transition: all 1s ease;
					height: 100%;
					left: 0;
					
					article {
						transition: all 1s ease;
						h1, section {
							opacity: 1;
							transition: all .3s ease;
						}
					}
						

					@media screen and (max-width: 776px) {
						width: 100%;
						position: relative;
						height: auto;
						-ms-flex-order : 2;
						    order : 2;
					}
					
					.donwload-map-section {
						padding: 0;
						width: 100%;
						height: 20vh;
						display: -ms-flexbox;
						display: flex;
					    -ms-flex-pack: center;
					        justify-content: center;
					    -ms-flex-align: center;
					        align-items: center;
					    background-color: #2B2B2B;
					    
						footer {

							.download-map {
								color: $gold;
								font-size: $small-size;
								border: solid 1px $gold;
							    padding: 0 20px;
							    line-height: 28px;
							    padding: 10px 20px;
							}
						}

						@media screen and (min-width: 769px) {
							display: none;
						}
					}
				}
				&.over-map {
					width: 20%;
					background-color: transparent;
					position: absolute;
					z-index: 2;
					height: 100%;
					right: 0;
					top: 0;
					transition: all 1s ease;
					display: none;

					@media screen and (min-width: 776px) {
						display: block;
					}

    				.left-text, .top-text, .right-text, .bottom-text {
    					opacity: 0;
						position: absolute;
						text-transform: uppercase;
						color: $light-gray;					
    				}

					.left-text {
						transition: all 1s ease;
						top: 50%;
					    transform: rotate(90deg);
					    
					    &:before {
					    	content: " ";
					    	width: 16px;
					    	height: 16px;
					    	display: block;
					    	background: url(../images/arrow-n.svg);
					    	position: absolute;
    						transform: translateX(-50%) rotate(90deg);
						    left: 50%;
						    bottom: -100%;
					    }
					}

					.top-text {
						top: 5%;
						left: 50%;
    					transform: translateX(-50%);
    					
						&:before {
					    	content: " ";
						    width: 14px;
						    height: 14px;
						    display: block;
						    background: url(../images/arrow-n.svg);
						    position: absolute;
						    left: 50%;
						    transform: translateX(-50%) rotate(-90deg);
						    bottom: 100%;
					    }
					}

					.right-text {
						transition: al 1s ease;
						top: 50%;
					    transform: rotate(-90deg);
					    right: 3%;
					    &:before {
					    	content: " ";
						    width: 16px;
						    height: 16px;
						    display: block;
						    background: url(../images/arrow-n.svg);
						    position: absolute;
						    transform: translateX(-50%) rotate(90deg);
						    left: 50%;
						    top: 100%;
					    }
					}

					.bottom-text {
						bottom: 5%;
						left: 50%;
						transform: translateX(-50%);
						&:before {
					    	content: " ";
						    width: 14px;
						    height: 14px;
						    display: block;
						    background: url(../images/arrow-n.svg);
						    position: absolute;
						    left: 50%;
						    transform: translateX(-50%) rotate(90deg);
						    top: 100%;
					    }
					}
				}
			}
		}

		.row {
			&.row-map {
				&.slide {
					&.hide {
						width: 12%;
						transition: all .6s ease;
						article {
							padding: 0;
							h1, section {
								opacity: 0;	
							}
						}
						.arrow-animate {
							right: 3%;
							@media screen and (min-width: 1025px) {
								right: 16%;	
							}
							span {
								margin-left: -29px;		
							}
						}
						.hide-content {
							opacity: 0;
						}

					}
				}

				&.over-map {
					&.hide {
						width: 88%;
						transition: all .6s ease; 
						pointer-events: none;
						
						.left-text, .top-text, .right-text, .bottom-text  {
						    opacity: 1;
						}
					}
				}
			}
		}
		
		.grid-less-half {
			article {
				background-color: $mapGrayBg;
				padding-bottom: 35px;

				h1 {
					color: $white;
					
					&:after {
						background: $white;
					}
				}
				section {

					h2 {
						color: $white;
					}	

					.content {
						p {
							width: 60%;
							margin-bottom: 35px;
							color: $white;
							font-size: 16px;
							@media screen and (max-width: 776px) {
								width: 100%;
							}

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}

			.arrow-animate {
				right: 5%;
				bottom: 45%;

				.wrapperArrow {
					.circle {
						svg {
							path {
								fill:$light-gray;
							}
						}
						.arrow {
							transform: rotate(-90deg);
							transition: transform .5s ease;
						}
						.right-arrow {
							transform: rotate(90deg);
							transition: transform .5s ease;
						}
					}
					&:hover {
						.circle {
							.arrow {
								transform: rotate(90deg);
							}
							.right-arrow {
								transform: rotate(-90deg);
							}
						}	
					}
				}

				span {
					color: $white;
					display: block;
					text-transform: uppercase;
					margin-left: -10px;
					font-family: $font-base;
					font-size: $smaller-size;
					letter-spacing: 2pt;
					transition: all 1s ease;
					// opacity: 1;
					transform: rotate(0deg);
				}

				span {
					&.hide {
						// opacity: 0;
						transform:rotate(360deg);
						margin-left: -30px;
					}
				}
				@media screen and(max-width: 776px) {
					display: none;
				}	
			}

			.arrow-animate.hide {
				right: 3%;
			}
		}

		@media screen and (max-width: 776px) {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			    flex-wrap: wrap;
		}
	}
}
nav.progressive-nav {
	display: none;

	@media screen and (min-width: 768px) {
		background: $black;
		padding: 25px;
		height: 70px;
		left: 0;
		z-index: 20;
		width: 100%;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		    justify-content: center;
		position: absolute;
		bottom: 0px;
	}

	&.fixed {
		position: fixed;
		top: 0px; 
		bottom: inherit;
	}
	
	.wrapper-nav {
		padding: 0;
      	width: 446px;
		height: 40px;
	}

	.logo-nav {
		margin-right: 15px;
		height: 21px;
		width: 18px;
		float: left;
		cursor: pointer;
		fill: $white;

		&:hover * {
			transition: all .5s ease;
			fill: $gold;
		}
	}

	.nodes {
		position: relative;
		margin-top: -5px;
		margin-left: 5px;
		float: left;
	}

	.line {
		position: absolute;
		width: 400px;
		height: 1px;
		top: 14px;
	  
		.red, .blue {
			width: 100%;
			height: 1px;
		}
	  
	   	.red-container {
			overflow: hidden;
			height: 1px;
			width: 58px;
	   	}
	  
		.red {
			background-color: $gold;
	  	}
	  
	  	.blue {
			background-color: $white;
	  	}
	}

	.node {
		margin-left: 90px;
		width: 20px;
		height: 20px;
		margin-top: 5px;
		float: left;
		margin-right: -10px;
		position: relative;

		.splash {
			box-shadow: 0px 0px 8px 2px rgba(220, 165, 110, 0.53);
			transform: translateZ(0px) scale( 1 );
			position: absolute;
			width: 20px;
			height: 20px;
			border-radius: 100%;
			pointer-events: none;
			opacity: 1;
		}

		&.active {

			.active-circle {
				transform: scale(1);
				opacity: 1;
			}

			.splash {
				transition: transform 1200ms, opacity 1500ms;
				transform: translateZ(0px) scale( 2.5 );
				opacity: 0 !important;
			}

			.inactive-circle {
				transition: opacity 200ms;
				opacity: 0; 
			}
		}

		.active-circle {
			transition: all 300ms cubic-bezier(.63,.62,.48,1.84);
			transform: scale(0.7);
			position: absolute;
			opacity: 0;
			width: 20px;
			height: 20px;
			border-radius: 100%;

			background: rgb(220,165,110); /* Old browsers */ 
			background: linear-gradient(45deg,  rgba(220,165,110,1) 0%,rgba(108,65,36,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */			

			.cover {
				transform: translateZ( 0px );
				width: 16px;
				height: 16px;
				background: black;
				border-radius: 100%;
				position: absolute;
				top: 2px;
				left: 2px;
			}

			.white {
				transition: all 300ms cubic-bezier(.63,.62,.48,1.84);
				width: 6px;
				height: 6px;
				background: $gold;
				border-radius: 100%;
				position: absolute;
				transform: translateX(5px) translateY(5px) scale(1);
			}
		}

		.inactive-circle {
			transition: all 300ms cubic-bezier(.63,.62,.48,1.84);
			width: 14px;
			height: 14px;
			display: block;
			background: #000;
			position: absolute;
			border-radius: 100%;
			border: 2px solid $white;
			top: 3px;
			left: 3px;
		}

		&:hover {
	      
	    	$quickTransition: all 200ms cubic-bezier(.63,.62,.48,1.84);
			cursor: pointer;

			.active-circle {
				transition: $quickTransition;
				transform: scale(0.84);

				.white {
					transition: $quickTransition;
					transform: translateX(4.7px) translateY(5px) scale(1.42);	
				}
			}

			.inactive-circle {
				transition: $quickTransition;
				transform: scale( 0.85 );
			}
		}
	}
}
.hero {
	height: 100vh;
	position: relative;
	background: $black;
	min-height: 320px;

	.btn-header {
		color: $gold;
		font-size: $small-size;
		border: solid 1px $gold;
	    padding: 0 20px;
	    line-height: 28px;
		position: absolute;
		top: 40px;
		z-index: 21;
	    transition: all .1s ease;

	    &.contact-us {
			right: 40px;
		}

		&.call-us {
			left: 40px;
			width: auto;
			display: block;

			@media screen and (min-width:768px) {
				display: none;
			}
		}
	    @media screen and (min-width: 768px) {
			position: fixed;
		}

		&:hover {
			background-color: $gold;
			color: $black;
		}
	}
	
	.hero-logo {
		width: 100px;
	    display: inline-block;
	    position: absolute;
	    left: 0;
	    right: 0;
	    top: 40%;
	    margin: auto;

	    @media screen and (max-width: 767px) and (orientation:landscape) {
	    	top: 25%;
	    }
	    @media screen and (min-width: 768px) {
			width: 160px;
		}
	}

	.arrow-animate {
		position: absolute;
	    left: 0;
	    right: 0;
	    bottom: 7%;
	    margin: auto;
	    text-align: center;
	    z-index: 10;
	    width: 51px;
	    
	    @media screen and (min-width: 768px) {
			bottom: 125px;
		}
	}
}
.sticky {
	width: 100%;
    height: 79px;
    background-color: #0d0d0d;
    position: absolute;
    bottom: -15px;
    padding: 0;
    margin: 0;
}