.row {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	position: relative;

	.concept {
		display: flex;
		min-height: 200px;

		&.dark {
			background-color: $black;
			color: $white;
		}
	}

	h1 {
		margin: auto;
		font-weight: $font-black;
		font-size: $title-middle;
		color: $white;
		text-transform: uppercase;
		position: relative;
	}
}

.grid-half {
	flex-direction: column;

	@include breakpoint (phablet) {
		flex-direction: row;
	}

	figure {
		display: none;

		@include breakpoint (phablet) {
			display: block;	
			overflow: hidden;

			img {
				height: 100%;
				width: auto;
			}
		}

	}

	article, figure {

		@include breakpoint (phablet) {
			flex: 1 1 50%;			
		}
		@include breakpoint (tablet) {
			flex: 1 1 50%;			
		}
	}
}

.grid-quarter {
	max-height: 400px;
	overflow: hidden;

	article, figure {
		flex: 1 1 25%;
		overflow: hidden;
	}

	figure {
		img {	
			width: auto;
			height: 100%;
		}
	}

	.concept {
		display: flex!important;
		height: 400px;

		@include breakpoint (phablet) {
			height: auto;
		}
	}
}