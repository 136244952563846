// FONTS
$font-base:				'Lato', sans-serif; /* 400 */
$font-bold:				700;
$font-black:			900;

// COLORS
$gold:				#ebb276;
$gray:				#000000;
$light-gray:		#858585;
$brown:				#704223;
$black:				#000;
$white:				#fff;
$mapGrayBg:			#393939;

// FONTS SIZES
$base-size:			14px;
$small-size:		13px;
$title-middle:		21px;
$big-size:			30px;
$smaller-size:		11px;

// BREAKPOINTS
@mixin breakpoint($point) {
	@if $point == desktop {
		@media (min-width: 1440px) { @content ; }
	}
	@else if $point == laptop {
		@media (min-width: 1200px) { @content ; }
	}
	@else if $point == tablet {
		@media (min-width: 1024px) { @content ; }
	}
	@else if $point == phablet {
		@media (min-width: 768px)  { @content ; }
	}
}
