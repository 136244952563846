.footer-section {
	width: 100%;
	background-color: $black;
	padding: 16px;

	.footer-content {
		min-height: 140px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 0;
		flex-direction: column;
		text-align: center;

		@media screen and (min-width: 768px) {
			flex-direction: row;
			padding: 0 70px;
			text-align: left;
		}			

		.info {
			
			display: flex;
			align-items: center;
			flex-direction: column;

			@media screen and (min-width: 768px) {
				flex-direction: row;
				flex: 2 1 33%;
			}

			h1 {
			    width: 100px;
				
				@media screen and (min-width: 768px) {
					margin-right: 30px;
					width: 75px;
				}
			}

			h2 {
				color: $light-gray;
				font-weight: normal;
				
				a {
					display: block;
					color: $light-gray;
				}
			}
		} 
		.to-top.arrow-animate {
			flex: 1 1 auto;
			position: static;
			margin: 20px 0;

			@media screen and (min-width: 768px){	
				margin: 0;
			}

			.wrapperArrow {
				width: 51px;
				margin: auto;

				.arrow {
					transform: rotate(180deg);
				}
			}
		}
		.footer-nav {
			color: $gold;
			font-weight: normal;

			.footer-facebook {
				margin-right: 20px;
			}
			
			@media screen and (min-width: 768px){	
				text-align: right;
				flex: 2 1 33%; 
			}
			
			a {
				color: $gold;
			}
			span {
				display: block;

				@media screen and (min-width: 768px){
					display: inline-block;
				}

				img {
					width: 93px;
					display: inline-block;
					vertical-align: middle;
					
					@media screen and (min-width: 768px){
						margin-right: 30px;
					}
				}
			}
		}
	}
}